
import React from 'react';
import Children from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LayoutConstant from '../components/layout';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  feeditem: {
    width: `calc(100% - {LayoutConstant.collapsedWidth} - 50)`
  }

}));

export default function FeedItem(props, id /* int */, 
    text /* String */,
    authorId /* int */,
    authorName /* String */,
    upvotes /* int */,
    flagged /* int = false */,
    experienceLevel /* int */,
    categoryTags /* Array */,
    awards /* Array */ ) {

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    return (
        <div class="feeditem" data-id="{id}" data-upvotes="{upvotes}">
            <article class="body">{props.children}</article>
            <Button variant="contained" color="primary">
                <span class="material-icons">thumb_up</span>&nbsp;Helpful
            </Button>
        </div>
    )
};

FeedItem.propTypes = {
    id: PropTypes.number,
    text: PropTypes.string,
    authorId: PropTypes.number,
    authorName: PropTypes.string,
    upvotes: PropTypes.number,
    flagged: PropTypes.bool,
    experienceLevel: PropTypes.number,
    categoryTags: PropTypes.array,
    awards: PropTypes.array
};
  
FeedItem.defaultProps = {
    id: 0,
    text: null,
    authorId: 0,
    authorName: "Anon",
    upvotes: 0,
    flagged: false,
    experienceLevel: 0,
    categoryTags: [],
    awards: []
};
