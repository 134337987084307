import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import FeedItem from "../components/feeditem"
import { LoremIpsum } from 'react-lorem-ipsum';

function CreateFeed(props) {
  let r = Math.round((Math.random() * 100),0);
  let arr = [];
  for (let i=0; i < r; i++) {
    arr.push(
      <FeedItem>
      <LoremIpsum 
        p={1} 
        avgWordsPerSentence={12} 
        avgSentencesPerParagraph={4} 
      />
    </FeedItem>
    );
  }
  return arr;
}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />    
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <CreateFeed />
    <Link to="/page-2/"><span class="material-icons">skip_next</span>Go to page 2</Link>
  </Layout>
)

export default IndexPage
